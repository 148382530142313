import React ,{useEffect,useRef} from 'react'
import {TimelineMax} from 'gsap';
import Pic from '../mouse.svg'
import hand from '../hand.png'

function Homepage() {
  let hello = useRef(null);
  let name = useRef(null);
  let description = useRef(null);
  ;
  let border = useRef(null);
  ;
  let btnLink = useRef(null);
  ;

  const t1 = new TimelineMax({
    delay:0.2
  });

  

  useEffect(()=>{
    t1.fromTo(hello, 0.5, {
      y: -50,
      opacity: 0,
      
    }, {
      y: 0,
      opacity: 1,
    }).fromTo(name, 0.5, {
      y: -50,
      opacity: 0,
      
    }, {
      y: 0,
      opacity: 1,
    }).fromTo(border, 0.5, {
      opacity: 0,
     
    }, {
      opacity: 1,
      
    })
    .fromTo(description, 0.8, {
      x: -800,
     
    }, {
      x: 0,
      
    },"-=0.4").fromTo(btnLink, 0.5, {
      opacity: 0,
     
    }, {
      opacity: 1,
      
    },)
    
  },[])


  return (
    <div className="full-height">
      <div className="container">
      <div className="showcase">
        <div className="left-text">
          <h2 className="hello-text" style={{fontSize:"1.7rem"}} ref={el=>{hello=el}}>Hello there! <span> <img src={hand} alt="" style={{width:"40px",marginLeft:"10px"}} /></span> </h2>
         
          <h1 ref={el=>{name=el}}>Kledjon <span style={{
            color:"#000"
        }}>Alushani.</span></h1>
          <div ref={el=>{border=el}} className="left-border">
          <p  ref={el=>{description=el}}>Creative Web Developer with a passion for developing beautifully-designed and functional <span style={{color:"#000",fontWeight:"600"}}>websites/applications</span> with the latest technology on the market.</p>
          </div>
          <div ref={el=>{btnLink=el}} className="hide-btn">
         
          <a className="main-btn" href="#my-work">View Projects</a>
          <a className="main-btn second-btn" href="#contact-me">Contact me</a>
          </div>
          
        </div>
        <div  className="right-image">
        {/* <img src={Avatar} alt=""/> */}
        </div>
      </div>
        
      <div style={{color:"#fff",textAlign:"center",fontSize:"1rem"}}>
          scroll
        </div>
        <div className="mouse">
          
        
          <img src={Pic} alt="" style={{width:"40px"}}/>
          
          
        </div>

      </div>
      
    </div>
  )
}

export default Homepage
