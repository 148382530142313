import React from 'react'
import Pic5 from '../fitness.png'
function Form5() {
    return (
        <div>
        <div className="project-container">
          <div className="project-title-header">Fitness Landing Page</div>
          <div className="project-image">
            <img src={Pic5} alt="" />
          </div>
  
          <div className="project-description-title">Project Description</div>
          <p>
          Simple landing page built to be responsive on all media devices (Desktop, Tablet, Mobile){" "}
          </p>
          <div className="project-build-with">
            <h3>Build With:</h3>
            <ul>
              <li>
                <i className="fas fa-caret-right"></i> Javascript{" "}
              </li>
              <li>
                <i className="fas fa-caret-right"></i> CSS 3
              </li>
              <li>
                <i className="fas fa-caret-right"></i> HTML 5
              </li>
              <li>
                <i className="fas fa-caret-right"></i> SASS
              </li>
              <li>
                <i className="fas fa-caret-right"></i>AOS
              </li>
            </ul>
          </div>
          <div className="project-footer">
            <a className="project-btn-view-live" href="https://fitnesspage.netlify.com" rel="noopener noreferrer" target="_blank" >
              View Live
            </a>
            <a className="project-btn-view-github" href="https://github.com/kledi-ui/FitnessLandingPage" rel="noopener noreferrer" target="_blank" >
              Github Code
            </a>
          </div>
        </div>
      </div>
    )
}

export default Form5
