import React from 'react'
import Pic3 from '../restaurant.png'
function Form3() {
    return (
        <div>
              <div className="project-container">
        <div className="project-title-header">
        Restaurant Website Landing Page
        </div>
        <div className="project-image">
          <img src={Pic3} alt="" />
        </div>

        <div className="project-description-title">Project Description</div>
        <p>
        Designed and developed with a conscious effort to avoid using any superfluous frameworks like Bootstrap.
        This Restaurant Website is mainly focused to show different skills on Html 5, Css 3 and Javascript not to be dependent from jQuery.{" "}
        </p>
        <div className="project-build-with">
            <h3>Build With:</h3>
          <ul>
            <li><i className="fas fa-caret-right"></i> Javascript </li>
            <li><i className="fas fa-caret-right"></i> CSS 3</li>
            <li><i className="fas fa-caret-right"></i> HTML 5</li>
         
          </ul>
        </div>
        <div className="project-footer">
            <a className="project-btn-view-live"href="https://chefpage.netlify.com" rel="noopener noreferrer" target="_blank" >
                View Live
            </a>
            <a className="project-btn-view-github" href="https://github.com/kledi-ui/Restaurant-Page" rel="noopener noreferrer" target="_blank">
                Github Code
            </a>
        </div>
      </div>
        </div>
    )
}

export default Form3
