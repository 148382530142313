import React from "react";
import Pic2 from "../movie.png";
function Form2() {
  return (
    <div>
      <div className="project-container">
        <div className="project-title-header">
          Movie Show Dashboard Application
        </div>
        <div className="project-image">
          <img src={Pic2} alt="" />
        </div>

        <div className="project-description-title">Project Description</div>
        <p>
          
          This is a Single Page Application built with React.js utilizing The
          Movie Database API. The main focus of this application is to fetch
          dynamic data from the api and manipulate this data in order to search,
          filter or display movies on a specific request. This application also shows information such as who starred in the movie, whether the film is profitable, from which production companies, etc{" "}
        </p>
        <div className="project-build-with">
            <h3>Build With:</h3>
          <ul>
            <li><i className="fas fa-caret-right"></i> React.js</li>
            <li><i className="fas fa-caret-right"></i> ASYNC/AWAIT</li>
            <li><i className="fas fa-caret-right"></i> React Routing</li>
            <li><i className="fas fa-caret-right"></i> Context API</li>
            <li><i className="fas fa-caret-right"></i> TMDB API</li>
            <li><i className="fas fa-caret-right"></i> CSS 3</li>
          </ul>
        </div>
        <div className="project-footer">
            <a className="project-btn-view-live"  href="https://movieshow.netlify.com" rel="noopener noreferrer" target="_blank">
                View Live
            </a>
            <a className="project-btn-view-github"href="https://github.com/kledi-ui/movieApp" rel="noopener noreferrer" target="_blank" >
                Github Code
            </a>
        </div>
      </div>
    </div>
  );
}

export default Form2;
