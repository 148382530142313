import React,{useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import "./App.css";
import Navbar from "./components/layout/Navbar";
import Homepage from "./components/pages/Homepage";
import MyWork from "./components/layout/MyWork";
import AboutMe from "./components/layout/AboutMe";
import ContactMe from "./components/layout/ContactMe";
import Copyrighted from "./components/layout/Copyrighted";
import Features from "./components/layout/Features";
import Modal from './components/layout/Modal';


function App() {
  AOS.init({
    offset: 300,
    duration: 600
  });

  const [modal,setModal] = useState(false);
  const [formState,setFormState] = useState(0);

  const openModal =()=>{
    setModal(true);
  }
  const closeModal =()=>{
    setModal(false);
  }

  const changeFormState =(current) =>{
    setFormState(current);
  } 
  
  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Navbar />
          <Homepage />
          <Features />
          <MyWork openModal={openModal} changeFormState={changeFormState}/>
          <AboutMe />
          <ContactMe />
          <Copyrighted />
          <Modal modal={modal} closeModal={closeModal} formState={formState} />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
