import React from "react";
import Form from "./Form";

function ContactMe() {
  return (
    <div id="contact-me">
        <div className="container">
        <div className="contact-me-grid">
        <div className="left-grid" data-aos="fade-right"  data-aos-delay="200" >
          <h2>Get in touch</h2>
        </div>
        <div className="right-grid" data-aos="fade-left"  data-aos-delay="200">
          <h2>Let’s work together</h2>
          <p
            id="contact-me-p"
            style={{
              fontSize: "21px",
              marginBottom: "1.8rem",
              padding: "1rem 0",
              color: "rgba(0,0,0,0.8)"
            }}
          >
            If you have a website idea in mind or you need some advice about
            product design and web development, feel free to contact me.
          </p>
          <Form />
        </div>
      </div>
        </div>
      
      

     
    </div>
  );
}

export default ContactMe;
