import React from 'react'

function Copyrighted() {
    return (
        <div>
            <div className="copyrighted-text" style={{background:"#3340FF",
        textAlign:"center",
        padding:"1rem 0",
        color:"white"}}>
            © 2020 All right Reserved 
            </div>
        </div>
    )
}

export default Copyrighted
