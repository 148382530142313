import React from 'react'
function List() {
  return (
    <div>
       <ul className="show-list">
          <li className="nav-item"><a href="/" >Home</a></li>
          <li className="nav-item"><a href="#my-work">Projects</a></li>
          <li className="nav-item"><a href="#about-me">About</a></li>
          <li className="nav-item"><a href="#contact-me">Contact</a></li>
          
        </ul>
    </div>
  )
}

export default List
