import React from "react";

function Features() {
  return (
    <div className="container">
      <div className="features" data-aos="fade-in">
        <div className="feature-1">
          <div className="feature-logo ">
          <i className="fas fa-user-graduate fa-3x"></i>
          </div>
          <div className="features-header">
              AUTODIDACT
          </div>
          <p>Got a degree in Software Engineering but also do web Development</p>
        
        </div>
        <div className="feature-1">
          <div className="feature-logo">
          <i className="far fa-clock fa-3x"></i>
          
          </div>
          <div className="features-header">
              DELIVERS
          </div>
          <p>Both in quality & clarity that's my north star</p>
        </div>
        <div className="feature-1">
          <div className="feature-logo">
          <i className="far fa-handshake fa-3x"></i>
          </div>
          <div className="features-header">
              TEAM PLAYER
          </div>
          <p>Listening & being part of a team is where I'm at</p>
        </div>
      </div>
    </div>
  );
}

export default Features;
