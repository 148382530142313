import React from 'react'
import Form2 from '../Projects/Form2'
import Form1 from '../Projects/Form1'
import Form3 from '../Projects/Form3'
import Form4 from '../Projects/Form4'
import Form5 from '../Projects/Form5'

function Modal({modal,closeModal,formState}) {



  const closeModalOuter = (e)=>{
 if(e.target.className==='modal-outer' || e.target.className==='fas fa-window-close close-btn'){
  closeModal();

 }
  }
  if(modal === false){
    return null;
  } else
  return (
    <div>
      <div className="modal-outer" onClick={closeModalOuter}>
        <div className="modal-inner">
        <i className="fas fa-window-close close-btn" ></i>
        
        {formState===1 && <Form1/>}
        {formState===2 && <Form2/>}
        {formState===3 && <Form3/>}
        {formState===4 && <Form4/>}
        {formState===5 && <Form5/>}
        </div>
      </div>
    </div>
  )
}

export default Modal
