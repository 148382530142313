import React from 'react'
import Pic1 from '../travel.png'
function Form1() {
    return (
        <div>
            <div className="project-container">
        <div className="project-title-header">
          Travel Explorer Company 
        </div>
        <div className="project-image">
          <img src={Pic1} alt="" />
        </div>

        <div className="project-description-title">Project Description</div>
        <p>
          This is a Full Stack Application built with <span className="blue-text">React.js</span> + <span className="blue-text">BOOTSTRAP</span> on Front-end. <span className="blue-text">NODE.js</span> + <span className="blue-text">EXPRESS.js</span> on Back-end utilizing <span className="blue-text">MySQL</span> as a Database Management system. <strong>Full features below:</strong>
                  </p>
        <div className="project-build-with project-1-build">
           
          <ul>
            <li><i className="fas fa-caret-right"></i> JWT Authentication</li>
            <li><i className="fas fa-caret-right"></i> Admin registration</li>
            <li><i className="fas fa-caret-right"></i> Admin login</li>
            <li><i className="fas fa-caret-right"></i> Password reset</li>
            <li><i className="fas fa-caret-right"></i> User Booking</li>
            <li><i className="fas fa-caret-right"></i> Update info</li>
            <li><i className="fas fa-caret-right"></i> Private Routes</li>
            <li><i className="fas fa-caret-right"></i> Encrypt passwords</li>
            <li><i className="fas fa-caret-right"></i> Database management</li>
            <li><i className="fas fa-caret-right"></i> Graphs and Statistics</li>
            <li><i className="fas fa-caret-right"></i> Tours CRUD</li>
            <li><i className="fas fa-caret-right"></i> Filter Data</li>
          </ul>
        </div>
        
        <div className="project-footer">
            <a className="project-btn-view-live" href="https://lit-tundra-72666.herokuapp.com/" rel="noopener noreferrer" target="_blank">
                View Live
            </a>
        </div>
      </div>
        </div>
    )
}

export default Form1
