import React  from "react";
import Pic from "../travel.png";
import Pic2 from "../movie.png";
import Pic3 from "../restaurant.png";
import Pic4 from "../agency.png";
import Pic5 from "../fitness.png";

function MyWork({openModal,changeFormState}) {







  return (
    <div id="my-work">
  
        <div className="my-work-grid">
          <div className="my-work-grid-left">
            <h2>My Work</h2>
          </div>
          <div className="my-work-grid-right">
            <h1>Latest Projects</h1>
            <p>
              Currently, I'm focused on full stack development ( MySQL , EXPRESS
              , REACT, NODE.js ).
            </p>

            <div className="my-work-flex">
              <div className="my-work-card-2 card-my-work" data-aos="fade-in"
                data-aos-delay="100" onClick={(e)=>{
                e.preventDefault();
                changeFormState(1);
                openModal();
              }} >
                <img src={Pic} alt="" />

                <i className="fas fa-search-plus"></i>
                <div className="my-work-name">Full Stack Application</div>
              </div>
              <div
                className="my-work-card-3 card-my-work"
                data-aos="fade-in"
                data-aos-delay="200"
                onClick={(e)=>{
                  e.preventDefault();
                  changeFormState(2);
                  openModal();
                }}
              >
                <img src={Pic2} alt="" />

                <i className="fas fa-search-plus"></i>
                <div className="my-work-name">React Application</div>
              </div>
              <div
                className="my-work-card-1 card-my-work"
                data-aos="fade-in"
                data-aos-delay="300"
               
                onClick={(e)=>{
                  e.preventDefault();
                  changeFormState(3);
                  openModal();
                }}
              >
                <img src={Pic3} alt="" />

                <i className="fas fa-search-plus"></i>
                <div className="my-work-name">Restaurant Page</div>
              </div>
              <div className="my-work-card-4 card-my-work" data-aos="fade-in"
                data-aos-delay="100"
                onClick={(e)=>{
                e.preventDefault();
                changeFormState(4);
                openModal();
              }}>
                <img src={Pic4} alt="" />

                <i className="fas fa-search-plus"></i>
                <div className="my-work-name">Responsive Landing Page</div>
              </div>
              <div className="my-work-card-4 card-my-work" data-aos="fade-in"
                data-aos-delay="200"  onClick={(e)=>{
                  e.preventDefault();
                  changeFormState(5);
                  openModal();
                }}>
                <img src={Pic5} alt="" />

                <i className="fas fa-search-plus"></i>
                <div className="my-work-name">Responsive Landing Page</div>
              </div>
            </div>
          </div>
        </div>
   

    
    </div>
  );
}

export default MyWork;
