import React from 'react'

function HideList(props) {

  const handleClick=(e)=>{
    props.changeMenu();
    // e.preventDefault();
  }


  return (
   
       <ul className="hide-list">
          <li className="nav-item" onClick={handleClick}><a href="/" to={'/'}>Home</a></li>
          <li className="nav-item" onClick={handleClick}><a href="#my-work">Projects</a></li>
          <li className="nav-item" onClick={handleClick}><a href="#about-me">About</a></li>
          <li className="nav-item" onClick={handleClick}><a href="#contact-me">Contact</a></li>
        </ul>
    
  )
}

export default HideList
