import React from "react";

function AboutMe() {
  return (
    <div id="about-me">
      
      <div className="container">
      <div className="about-me-flex">
        <div className="about-me-image" data-aos="fade-right"  data-aos-delay="200" >
           <h2>Background</h2>
        </div>
        <div className="about-me-text" data-aos="fade-left"  data-aos-delay="200">
       
          <h2> Short story about me</h2>
          <p>
            I’m a passionate Web Developer from Tirana, Albania. What started as
            a curiosity, has grown into a passion creating beautifully designed,
            intuitive and functional websites. This curiosity helped me to stay
            up-to-date from year to year and I’m still learning every single day
            to ensure I can deliver the most modern and effective solutions as a
            developer. I believe in working hard and smart instead of searching
            for “hacks and shortcuts”.
          </p>
         
        </div>
      </div>
      <div className="about-me-flex">
        <div className="about-me-image" data-aos="fade-right"  data-aos-delay="200" >
           <h2>Skills</h2>
        </div>
        <div className="about-me-text" data-aos="fade-left"  data-aos-delay="200">
       
        <h2>Technologies that i use</h2>
          <div className="about-me-ul">
            <ul>
              <li>
                <i className="devicon-html5-plain-wordmark colored icon"></i>
              </li>
              <li>
                <i className="devicon-css3-plain-wordmark colored icon"></i>
              </li>
              <li>
                <i className="devicon-sass-original colored icon"></i>
              </li>
              <li>
                <i className="devicon-javascript-plain colored icon"></i>
              </li>
              <li>
                <i className="devicon-react-original-wordmark colored icon"></i>
              </li>
              <li>
                <i className="devicon-nodejs-plain colored icon"></i>
              </li>
              <li>
                <i className="devicon-git-plain colored icon"></i>
              </li>
            
              <li>
                <i className="devicon-mongodb-plain-wordmark colored icon"></i>
              </li>
              <li>
                <i className="devicon-mysql-plain-wordmark colored icon"></i>
              </li>
              <li>
                <i className="devicon-bootstrap-plain-wordmark colored icon"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>

      </div>
    
      
    </div>
  );
}

export default AboutMe;
