import React,{useState,useRef} from 'react'
import List from './List';
import HideList from './HideList';
import {TimelineMax} from 'gsap';

function Navbar() {
  const t1 = new TimelineMax({
    
  });
  let line1 = useRef(null);

  let line2 = useRef(null);
  let line3 = useRef(null);

 



  const [menu,setMenu]=useState(false);
  const handleBtn=(e)=>{
   e.preventDefault();
    setMenu(!menu);
    if(menu===false){
      // tl.play();
      t1.fromTo(line1, 0.3, {
        y: -5,
      }, {
        y: 5
      }).fromTo(line3, 0.3, {
        y: 5,
      }, {
        y: -5
      },"=-0.3").fromTo(line2, 0.1, {
        opacity: 1,
      }, {
        opacity:0
      }).fromTo(line1, 0.3, {
        rotation:0
      }, {
        rotation:-45
      },)
      .fromTo(line3, 0.3, {
        rotation:0
      }, {
        rotation:45
      },"=-0.3");
      
    }else if(menu===true){
      t1.fromTo(line1, 0.3, {
        rotation:-45

      }, {
        rotation:0

      }).fromTo(line3, 0.3, {
        rotation:45
      }, {
        rotation:0
      },"=-0.3").fromTo(line2, 0.1, {
        opacity: 0,
      }, {
        opacity:1
      })
      .fromTo(line1, 0.3, {
        y: 5,

      }, {
        y: -5,
        
      }).fromTo(line3, 0.3, {
        y: -5,
      }, {
        y: 5
      },"=-0.3");
    }
  }

  const changeMenu =()=>{
    setMenu(!menu);
    if(menu===false){
      // tl.play();
      t1.fromTo(line1, 0.3, {
        y: -5,
      }, {
        y: 5
      }).fromTo(line3, 0.3, {
        y: 5,
      }, {
        y: -5
      },"=-0.3").fromTo(line2, 0.1, {
        opacity: 1,
      }, {
        opacity:0
      }).fromTo(line1, 0.3, {
        rotation:0
      }, {
        rotation:-45
      },)
      .fromTo(line3, 0.3, {
        rotation:0
      }, {
        rotation:45
      },"=-0.3");
      
    }else if(menu===true){
      t1.fromTo(line1, 0.3, {
        rotation:-45

      }, {
        rotation:0

      }).fromTo(line3, 0.3, {
        rotation:45
      }, {
        rotation:0
      },"=-0.3").fromTo(line2, 0.1, {
        opacity: 0,
      }, {
        opacity:1
      })
      .fromTo(line1, 0.3, {
        y: 5,

      }, {
        y: -5,
        
      }).fromTo(line3, 0.3, {
        y: -5,
      }, {
        y: 5
      },"=-0.3");
    }
  }

  return (
    <div className="Navbar">
    <div className="container">
    <nav>
        <div className="logo">
       
        <div style={{fontSize:"1.6rem",
        }}> <span style={{color:"#3340FF",
        background:"#fff",
      padding:"0 0.5rem"}}>K</span> <span style={{color:"white"}}>|</span> <span className="a-letter" style={{color:"#FFF"
        }}>A</span></div>
        </div>
       <List/>
       {menu===true ? <HideList changeMenu={changeMenu}/>:null}
        <div className="btn-toggle" onClick={handleBtn}>
        <div ref={el=>{line1=el}} className="line line1">
        </div>
        <div ref={el=>{line2=el}} className="line line2">
        </div>
        <div ref={el=>{line3=el}} className="line line3">
        </div>
        </div>
      </nav>
    </div>
      
    </div>
  )
}





export default Navbar
