import React from 'react'

function Form() {
  return (
    <form className="modal" name="contact" method="post">
    <input type="hidden" name="form-name" value="contact" />
          <h3 style={{padding:"0.5rem 0"}}>Name </h3>
         <input type="text" required name="name" placeholder="Enter your full name"  />
         <h3 style={{padding:"0.5rem 0"}}>Email </h3>
      <input type="email" required name="email" placeholder="Please enter your current email" />
      <h3 style={{padding:"0.5rem 0"}}>Message </h3>
      <textarea name="message" placeholder="Type your message here..." />
        <button className="modal-btn" type="submit">Send Email</button>
    
    </form>
  )
}

export default Form
