import React from "react";
import Pic4 from "../agency.png";
function Form4() {
  return (
    <div>
      <div className="project-container">
        <div className="project-title-header">Digital Agency Website</div>
        <div className="project-image">
          <img src={Pic4} alt="" />
        </div>

        <div className="project-description-title">Project Description</div>
        <p>
          Digital Agency Website is a custom web page built with a combination
          of popular libraries to create a single scrolling and responsive web
          page. The main focus was to create a beautiful atractive landing page that shows various animations on scroll event{" "}
        </p>
        <div className="project-build-with">
          <h3>Build With:</h3>
          <ul>
            <li>
              <i className="fas fa-caret-right"></i> Javascript{" "}
            </li>
            <li>
              <i className="fas fa-caret-right"></i> CSS 3
            </li>
            <li>
              <i className="fas fa-caret-right"></i> HTML 5
            </li>
            <li>
              <i className="fas fa-caret-right"></i> FullPage.js
            </li>
            <li>
              <i className="fas fa-caret-right"></i>TweenMax.js
            </li>
          </ul>
        </div>
        <div className="project-footer">
          <a className="project-btn-view-live" href="https://digital-agency.netlify.com/" rel="noopener noreferrer" target="_blank" >
            View Live
          </a>
          <a className="project-btn-view-github" href="https://github.com/kledi-ui/digital-agency" rel="noopener noreferrer" target="_blank" >
            Github Code
          </a>
        </div>
      </div>
    </div>
  );
}

export default Form4;
